'use client'

import { cn } from '@/util/cn'
import { relativeGifClick } from '@/util/url'
import { Gif, GifOverlayProps, VideoOverlay } from '@giphy/react-components'
import { ComponentProps } from 'react'

type GifProps = ComponentProps<typeof Gif>
type Props = Required<Pick<GifProps, 'width'>> & GifProps
const GifWithVideoOverlay = (props: Props) => {
    return (
        <Gif
            {...props}
            className={cn('[&_img]:object-cover', props.className)}
            onGifClick={(gif, e) => {
                relativeGifClick(gif, e)
            }}
            overlay={(overlayProps: GifOverlayProps) => (
                <VideoOverlay
                    {...overlayProps}
                    width={props.width}
                    height={props.height}
                    className="[&_video]:object-cover"
                />
            )}
        />
    )
}
export default GifWithVideoOverlay
