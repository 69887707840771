'use client'
import { Video as _Video } from '@giphy/react-components'
import { ComponentProps, useContext, useState } from 'react'
import { VideoContext } from './video-context-manager'

const Video = (props: ComponentProps<typeof _Video>) => {
    const { ccEnabled: showCC } = useContext(VideoContext)
    // show controls for one loop, then hide them
    const [showControls, setShowControls] = useState(true)
    return (
        <_Video
            {...props}
            style={{ cursor: 'pointer', ...props.style }}
            ccEnabled={showCC}
            loop
            controls
            persistentControls={showControls}
            hideAttribution
            hideTitle
            onLoop={(count: number) => {
                if (count > 0) {
                    setShowControls(false)
                }
            }}
        />
    )
}
export default Video
