'use client'

import { useGiphyFetch } from '@/app/util/use-gf'
import VideoGrid from '@/components/grid/video-grid'
import { IGif } from '@giphy/js-types'

type Props = { gif: IGif; initialGifs: IGif[] }
const UpNextVideoGrid = ({ initialGifs, gif }: Props) => {
    const gf = useGiphyFetch()
    const getTrackingUrl = (page: number) => `/clips/${gif.id}/${page}`
    return (
        <VideoGrid
            fetchGifs={(offset: number) => gf.related(gif.id as string, { type: 'videos', offset, limit: 25 })}
            initialGifs={initialGifs}
            gaTrackingUrl={getTrackingUrl}
        />
    )
}
export default UpNextVideoGrid
