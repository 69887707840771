'use client'

import Button from '@/components/button'
import { useContext } from 'react'
import { VideoContext } from './video-context-manager'

function CaptionsButton() {
    const { ccEnabled, setCCEnabled } = useContext(VideoContext)
    return (
        <Button
            onClick={() => {
                setCCEnabled(!ccEnabled)
            }}
        >
            {ccEnabled ? 'Hide' : 'Show'} Captions
        </Button>
    )
}

export default CaptionsButton
