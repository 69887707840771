'use client'

import OpenEditPanel from '@/app/(site)/gifs/[slug]/menu/open-edit-panel'
import ReportButtonManager from '@/app/(site)/gifs/[slug]/menu/report-gif'
import { GifPanelContext } from '@/app/components/detail/gif-panel-context-manager'
import { ClickToShowModal } from '@/app/components/modal'
import UserContext from '@/context/user-context'
import { canDownload, canEdit } from '@/util/user-permissions'
import { IGif } from '@giphy/js-types'
import { useContext } from 'react'
import { VideoContext } from '../video-context-manager'
import { Caption, Download, Embed, FullScreen, Plus, Report } from './gradient-icons'
type Props = { gif: IGif; children: React.ReactNode }
import EditIcon from '@/app/icons/edit'

const menuItemClassName =
    'flex justify-between cursor-pointer select-none hover:text-giphyBlue [&_label]:pointer-events-none'

const PopUpMenu = ({ gif, children }: Props) => {
    const { setPanel, panel } = useContext(GifPanelContext)
    const { user } = useContext(UserContext)
    const { setCCEnabled, ccEnabled } = useContext(VideoContext)

    return (
        <div className="group relative">
            <div className="cursor-pointer">{children}</div>
            <div className="absolute bottom-0 right-0 hidden group-hover:block">
                <div className="bg-giphyDarkestGrey text-md mb-6 flex w-[200px] flex-col gap-4 p-4 [&_svg]:inline-block">
                    {user && canEdit(user, gif) && (
                        <OpenEditPanel gif={gif} className={menuItemClassName}>
                            <label>Edit?</label> <EditIcon className="size-4" />
                        </OpenEditPanel>
                    )}
                    {user && (
                        <ClickToShowModal className={menuItemClassName} modal={{ name: 'addToCollection' }}>
                            <label>Add to Collection</label> <Plus />
                        </ClickToShowModal>
                    )}
                    <div
                        className={menuItemClassName}
                        onClick={() => {
                            setCCEnabled(!ccEnabled)
                        }}
                    >
                        <label>{ccEnabled ? 'Show' : 'Hide'} Captions</label> <Caption />
                    </div>
                    <div
                        className={menuItemClassName}
                        onClick={() => {
                            setPanel(panel === 'embed' ? 'none' : 'embed')
                        }}
                    >
                        <label>Embed Code</label> <Embed />
                    </div>
                    <div
                        className={menuItemClassName}
                        onClick={() => {
                            const $el = document.querySelector(`[data-giphy-id=${gif.id}]`)
                            if ($el) {
                                $el.requestFullscreen()
                            }
                        }}
                    >
                        <label>Full Screen</label> <FullScreen />
                    </div>
                    <ReportButtonManager gif={gif}>
                        <div className={menuItemClassName}>
                            <label>Report</label> <Report />
                        </div>
                    </ReportButtonManager>
                    {user && canDownload(user, gif) && (
                        <div className={menuItemClassName}>
                            <label>Download</label> <Download />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default PopUpMenu
