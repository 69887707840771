'use client'
import EmbedPanel from '@/app/(site)/gifs/[slug]/menu/desktop/embed-panel'
import SharePanel from '@/app/(site)/gifs/[slug]/menu/desktop/share-panel'
import { GifPanelContext } from '@/app/components/detail/gif-panel-context-manager'
import { IGif } from '@giphy/js-types'
import { useContext } from 'react'
type Props = { gif: IGif }
const DesktopPanels = ({ gif }: Props) => {
    const { panel } = useContext(GifPanelContext)
    let Component = null
    switch (panel) {
        case 'share':
            Component = <SharePanel gif={gif} />
            break
        case 'embed':
            Component = <EmbedPanel gif={gif} />
            break
    }
    return Component
}
export default DesktopPanels
