import { IGif } from '@giphy/js-types'
import { ILoggedInUser } from 'types'

const isArtistOrPartner = (user: ILoggedInUser) => {
    return user?.user_type === 'artist' || user?.user_type === 'partner'
}

export const canEdit = (user: ILoggedInUser, gif: IGif) => {
    if (user?.is_staff) {
        return true
    }
    return isOwnGif(gif, user)
}

export const canDownload = (user: ILoggedInUser, gif: IGif) => {
    if (user?.is_staff) {
        return true
    }
    return isArtistOrPartner(user) && isOwnGif(gif, user)
}

function isOwnGif(gif: IGif, user: ILoggedInUser) {
    if (!user || !gif) {
        return false
    }
    return gif?.user?.id === user?.id
}
